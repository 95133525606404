<template>
    <SuccessScreen
        titleKey="edit-profile.success.title"
        messageKey="edit-profile.success.message"
        buttonTextKey="edit-profile.success.button-text"
    />
</template>

<script>
import SuccessScreen from '@/components/SuccessScreen.vue';

export default {
    name: 'ProfileUpdateSuccess',
    components: {
        SuccessScreen
    }
}
</script>